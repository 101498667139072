#slider {
  margin: 20px 0;

  .slide {
    margin: 0 5px;
  }
}

.slider-wrapper {
  padding: 0 25px;
}

.slick-prev:before, .slick-next:before {
  color: $color-black;
}
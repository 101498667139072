$header-height: 15vh;
$main-height: 85vh;
$footer-height: 0vh;

.header {
  height: $header-height;
}

.main {
  height: $main-height;
  padding: 0 1vh;
}

.footer {
  height: $footer-height;
}

.fullheight {
  height: $main-height;
  position: relative;
}

$spacer: 14px;

.a-mb-1 {
  margin-bottom: $spacer * 0.25;
}

.a-mb-2 {
  margin-bottom: $spacer * 0.5;
}

.a-mb-3 {
  margin-bottom: $spacer;
}

.a-mb-4 {
  margin-bottom: $spacer * 1.5;
}

.a-mb-5 {
  margin-bottom: $spacer * 3;
}

.a-mb-6 {
  margin-bottom: $spacer * 4.5;
}

.a-mt-7 {
  margin-top: $spacer * 6;
}

.a-mt-1 {
  margin-top: $spacer * 0.25;
}

.a-mt-2 {
  margin-top: $spacer * 0.5;
}

.a-mt-3 {
  margin-top: $spacer;
}

.a-mt-4 {
  margin-top: $spacer * 1.5;
}

.a-mt-5 {
  margin-top: $spacer * 3;
}

.a-mt-6 {
  margin-top: $spacer * 4.5;
}

.a-mt-7 {
  margin-top: $spacer * 6;
}

.imaginary_event-map {
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
}

.event-teaser {
  position: relative;
  &:hover {
    .event-name {
      text-decoration: underline;
    }
  }

  .event-location {
    color: $color-black;
  }

  .event-name {
    @include headingFont;
    font-size: 16px;
    margin: 1px 0px 0px;
  }

  .event-date {
    color: $color-black;
  }

  .event-thumbnail {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
}

$expanded-group-height: 300px;

.event-list-grouped-country,
.event-list-grouped-year {
  position: relative;
}

.event-list-group {
  display: inline-block;
  box-sizing: border-box;
  width: 25%;
  height: 40px;
  margin: 0;
  border: 3px solid #fff;
  vertical-align: top;

  @include responsive($xs) {
    width: 100%;
  }

  button {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: $color-turquoise-light;
    text-align: center;
    padding: 0 34px 0 0;
    border: none;
    border-radius: 0;
    @include headingFont;
    font-size: 15px;
    line-height: 36px;

    @include responsive($sm-only) {
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border: 1px solid #9c9c9c;
    }
  }

  .event-list-group-size {
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
    height: 34px;
    background-color: $color-turquoise-dark;
    text-align: center;
    color: #fff;
    font-family: Arial;
    z-index: 2;
  }

  &.event-list-group-expanded {
    button {
      background-color: darken($color-turquoise-light, 10%);
      &:focus {
        border: none;
      }

      .event-list-group-size {
        background-color: darken($color-turquoise-dark, 10%);
      }

      &:before {
        // Arrow
        content: " ";
        display: block;
        height: 25px;
        width: 25px;
        background-color: darken($color-turquoise-light, 10%);
        transform: rotate(45deg);
        position: absolute;
        margin-left: -12px;
        left: 50%;
        bottom: -12px;
        z-index: -1;
      }
    }
  }
}

.event-list-grouped-year {
  margin-bottom: 30px;
  .event-list-group {
    width: 10%;
    height: 60px;
    bottom: 0;

    @include responsive($xs) {
      width: 25%;
    }

    button {
      padding: 0 0 20px 0;
    }

    &:last-child {
      button {
        font-size: 14px;

        @include responsive($sm-only) {
          font-size: 12px;
        }

        .event-list-group-size {
          font-size: 15px;
        }
      }
    }

    .event-list-group-size {
      bottom: 0;
      top: auto;
      width: 100%;
      height: 20px;
      line-height: 20px;
    }

    &.event-list-group-expanded {
      button {
        &:before {
          // Arrow
          background-color: darken($color-turquoise-dark, 10%);
        }
      }
    }
  }
}

.event-list-grouped-country {
  margin-bottom: 30px;
  .event-list-group {
    button {
      &:before {
        margin-left: -27px;
      }
    }
  }
}

.event-list {
  height: $expanded-group-height - 20px;
  overflow: scroll;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 3px 10px;
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding-top: 30px;
  background-color: #fff;

  li.event-data {
    list-style: none;
    list-style-image: none;
    margin: 0 0 15px 0;
    padding: 0;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    box-sizing: border-box;

    @include responsive($xs) {
      width: 100%;
    }
  }
}

.event-list.accordion-enter,
.event-list.accordion-exit {
  //border-bottom: none;
}

.event-list-group {
  transition: margin-bottom 0.25s 0.25s;
  margin-bottom: 0;

  &.event-list-group-expanded {
    margin-bottom: $expanded-group-height;
    transition: margin-bottom 0.25s;
  }
}

.event-list.accordion-exit {
  //transition: max-height 0.25s 0.25s;
  //max-height: $expanded-group-height - 20px;
  transition: opacity 0.25s;
  opacity: 1;
}

.event-list.accordion-exit-active {
  //max-height: 0;
  opacity: 0;
}

.event-list.accordion-enter {
  //transition: max-height 0.25s;
  //max-height: 0;
  transition: opacity 0.25s;
  opacity: 0;
}

.event-list.accordion-enter-active {
  //max-height: $expanded-group-height - 20px;
  opacity: 1;
}

.event-list-sort {
  padding: 14px 0 0;
  margin: 10px 0;
  li {
    display: inline-block;
    list-style: none;
    list-style-image: none;
    margin-right: 10px;

    @include responsive($xs) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      @include headingFont;
      font-size: 15px;
      color: $color-white !important;
      background-color: $color-gray-disabled;
      padding: 5px 15px;
      line-height: 15px;
      border-radius: 1em;
      text-decoration: none !important;
    }

    &.active {
      a {
        background-color: $color-blue-bright;
      }
    }
  }
}

ul.event-grid {
  width: 100%;
  margin: 0 0 40px 0;
  padding: 0;
  overflow: hidden;

  li {
    list-style: none;
    list-style-image: none;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;

    width: 25%;
    margin: 0;
    padding: 10px;

    @include responsive($xs) {
      width: 100%;
    }
  }
}

.event-grid-empty {
  text-align: center;
  margin-bottom: 30px;
  color: #666;
}

.stat-balloon {
  display: inline-block;
  background-color: $color-orange;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-align: center;
  margin: 0 3px;
}

.stat-text {
  margin: 1.5em 0;
}

// Sans-serif
$font-stack-arial: Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-stack-arial-black: "Arial Black", "Arial Bold", Gadget, sans-serif;
$font-stack-arial-narrow: "Arial Narrow", Arial, sans-serif;
$font-stack-tahoma: Tahoma, Geneva, Verdana, sans-serif;
$font-stack-trebuchet-ms: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
$font-stack-verdana: Verdana, Geneva, sans-serif;

// Serif
$font-stack-times-new-roman: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
$font-stack-georgia: Georgia, Times, "Times New Roman", serif;
$font-stack-palatino: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;

// Monospaced
$font-stack-courier-new: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;

// Other
$font-stack-jon-tan: Baskerville, Garamond, Palatino, "Palatino Linotype", "Hoefler Text", "Times New Roman", serif;
$font-stack-facebook: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, Verdana, Arial, sans-serif;
$font-stack-cambria: Cambria, Georgia, serif;
$font-stack-sushi-n-robots: "Hoefler Text", Constantia, Palatino, "Palatino Linotype", "Book Antiqua", Georgia, serif;

$font-stack-open-sans: 'Open Sans', Helvetica, Arial, sans-serif;
$font-stack-playfair: 'Playfair Display', Georgia, Times, "Times New Roman", serif;
$font-stack-oswald: 'Oswald', Arial, "Helvetica Neue", Helvetica, sans-serif;

@mixin openSansRegular { font-family: $font-stack-open-sans; font-weight: 400; }
@mixin openSansLight { font-family: $font-stack-open-sans; font-weight: 300; }
@mixin openSansSemiBold { font-family: $font-stack-open-sans; font-weight: 600; }
@mixin openSansBold { font-family: $font-stack-open-sans; font-weight: 700; }

@mixin playfairDisplayRegular { font-family: $font-stack-playfair; font-weight: 400; }
@mixin oswaldRegular { font-family: $font-stack-oswald; font-weight: 400; }
@mixin oswaldBold { font-family: $font-stack-oswald; font-weight: 700; }

@mixin ralewayRegular { font-family: Raleway, sans-serif; font-weight: 400; }

@mixin marvelRegular {   font-family: 'Marvel'; font-style: normal; font-weight: 400; }
@mixin marvelBold {   font-family: 'Marvel'; font-style: normal; font-weight: 700; }

@mixin futura { font-family: 'FuturaPTMedium-Reg', "Trebuchet MS", Arial, sans-serif; font-style: normal; font-weight: 400; }
@mixin roboto { font-family: 'Roboto', sans-serif;  font-style: normal; font-weight: 400; }
.news-teaser {
  display: block;
  color: $color-black;
  .news-image {
    transition: transform 0.3s, box-shadow 0.3s;
  }

  &:hover {
    text-decoration: none;
    color: $color-black;

    .news-image {
      transform: scale(1.05);
      box-shadow: 0 0 30px -25px #000;
      transform-origin: center center;
    }
  }
}

.news-image {
  position: relative;
  background-color: $color-gray-light;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  box-shadow: 0 0 20px -12px #000;
  margin-bottom: 1.2em;

  img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.news-title {
  font-size: 20px;
  line-height: 24px;
  text-transform: none;
}

.news-body {
  margin-top: 0.8em;
  font-size: 15px;
}

.social {
  font-size: 30px;
  line-height: 40px;
  margin: 5px 5px;
  color: white;
  display: inline-block;
  background-color: $color-cherry;
  padding: 0.2em;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &:hover {
    color: white;
  }
}

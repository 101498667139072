$xs: ( max:  767px ); // Phones only
$sm: ( min:  768px ); // Tablets and over
$md: ( min:  992px ); // Small desktops and over
$lg: ( min: 1200px ); // Large desktops
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 ); // Only tablets
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 ); // Only small desktops
$sm-md: ( min: map-get($sm, min), max: map-get($lg, min) - 1 ); // Phones and tablets
$xs-sm: ( max: map-get($md, min) - 1 ); // Phones and tablets

@mixin responsive($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @media screen and #{$query} { @content; }
}
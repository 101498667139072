$timeline-line-width: 6px;
$timeline-dot-width: 18px;

.timeline-row {
  position: relative;
  padding-bottom: 5px;
  padding-top: 10px;

  @include responsive($xs) {
    padding-left: 40px;
  }

  // Line
  &:before {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 23.1%;
    height: 100%;
    width: $timeline-line-width;
    background-color: white;

    @include responsive($md-only) {
      left: 22.6%;
    }

    @include responsive($sm-only) {
      left: 13.7%;
    }

    @include responsive($xs) {
      left: 24px;
    }
  }

  &:last-child {
    margin-bottom: 40px;
  }
}

.timeline-year {
  margin-bottom: 1.2em;
  text-align: right;
  @include headingFont;
  font-size: 22px;
  position: relative;
  margin-right: 20px;

  @include responsive($xs) {
    text-align: left;
  }

  // Dot
  &:after {
    display: block;
    content: " ";
    position: absolute;
    top: 5px;
    right: -1 * $timeline-dot-width - 6px;
    height: $timeline-dot-width;
    width: $timeline-dot-width;
    border-radius: 50%;
    background-color: white;

    @include responsive($xs) {
      left: -37px;
      right: auto;
    }
  }
}

.timeline-item {
  position: relative;
  margin-bottom: 1.2em;
  background-color: white;
  color: $color-black;
  padding: 5px 10px;
  box-shadow: 6px 7px rgba(0, 0, 0, 0.15);
  strong {
    font-size: 16px;
    color: #5a5a5a;
  }

  a {
    color: #04869c;
    &:hover {
      text-decoration: underline;
    }
  }
}

$color-white-real: #fff;
$color-black-real: #000;

$color-white: $color-white-real;
$color-black: $color-black-real;
$color-black-soft: #333;

$color-gray-light: #eee;
$color-gray: #b3b3b3;
$color-bold-text: #565656;
$color-gray-disabled: #a8afb9;

$color-blue: #019eb7;
$color-blue-bright: #019eb7;
$color-turquoise: #019eb7;
$color-turquoise-light: #d1f0f5;
$color-turquoise-dark: #019eb7;
$color-orange: #ffd352;

$color-yellow-light: #ffec8e;
$color-turquoise-bright: #019eb7;
$color-cherry: #ff006a;

@mixin neg {
  color: $color-white;
  -webkit-font-smoothing: antialiased !important;
}

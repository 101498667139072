body {
  @include regularText;
}

a {
  color: darken($color-turquoise, 5%);

  &:hover {
    color: darken($color-turquoise, 15%);
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  @include headingFont;
  text-transform: uppercase;
}

h1 {
  margin: 0 0 30px;
}

h2 {
  margin: 10px 0 20px;

  @include responsive($xs) {
    font-size: 22px;
  }
}

.medium {
  font-size: 16px;
  line-height: 1.5;

  @include responsive($xs) {
    font-size: 15px;
  }
}

.large {
  font-size: 20px;
  line-height: 1.4;

  @include responsive($xs) {
    font-size: 18px;
  }
}

.video {
  margin-bottom: 1.2em;
  box-shadow: 0 0 30px -10px #000;
}

.margins {
  margin-top: 12px;
  margin-bottom: 12px;
}

img.thumb {
  height: 200px;
  width: auto;
}

.panel {
  background-color: $color-yellow-light;
  padding: 2em;
}

.btn-primary {
  background-color: $color-turquoise-bright;
  border-color: $color-turquoise-bright;
}

.banner-matrix {
  background-color: #24ddb6;
  padding: 1em;

  h2 {
    color: white;
    margin-bottom: 0.2em;
  }

  .btn-primary {
    background-color: #2a0d7d;
    border-color: #2a0d7d;
    color: white;
    font-weight: bold;
    margin-top: 3em;
  }

  .logo {
    margin-top: 3em;
  }
}

@mixin columned {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  @include responsive($xs-sm) {
    max-width: none;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.project {
  h1, h2, h3, h4, h5, h6, p, ul, ol {
    @include columned;
  }

  font-size: 18px;
  line-height: 1.7;
  color: #232323;
  margin-bottom: 10rem;

  @include responsive($xs-sm) {
    font-size: 17px;
    line-height: 1.5;
  }

  h1 {
    @include futura;
    font-weight: 400;
    margin-bottom: 1em;
    font-size: 2.5em;

    .highighted {
      display: inline-block;
      background-color: #ffff5f;
      padding: 0.1em 0.3em;
      margin-left: -0.3em;
    }
  }

  h2 {
    @include futura;
    font-weight: 400;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
    font-size: 2em;
  }

  ul, ol {
    margin-bottom: 1em;
    padding-left: 1em;
  }

  .lead {
    font-size: 1.25em;
    line-height: 1.5;

    @include responsive($xs-sm) {
      font-size: 1.125em;
    }
  }

  figure, .video-js-wrap, .app {
    @include columned;
    margin-top: 3em;
    margin-bottom: 3em;

    @include responsive($xs-sm) {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }

    & > img, & > iframe {
      display: block;
      width: 100%;
      border: none;
    }

    & > figcaption, & > .caption {
      font-size: 0.8em;
      line-height: 1.5em;
      background-color: #ddd;
      padding: 0.4em 0.6em;
      margin-top: 1em;
    }

    &.large {
      max-width: 1200px;
      @include responsive($md-only) {
        max-width: 768px;
      }

      @include responsive($xs-sm) {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        & > figcaption, & > .caption {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }

    &.border {
      border: none !important;
      & > img, & > iframe, & > .video-js {
        border: 2px solid $color-black;
        margin-bottom: 5px;
      }
    }
  }

  .side-by-side {
    width: 96%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;

    @include responsive($xs-sm) {
      flex-direction: column;
      width: auto;
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    &.side-by-side-small {
      max-width: 800px;
    }

    .side {
      width: 48%;

      @include responsive($xs-sm) {
        width: auto;
      }
    }
  }

  .video-js-wrap {
    .video-js {
      .vjs-big-play-button {
        font-size: 5em;
      }
    }
  }

  .project-description {
    p {
      margin-bottom: 1em;
    }
  }
}

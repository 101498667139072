.section {
  padding: 40px 0 40px;
}
.section-dark {
  background-color: $color-gray-light;
}

.section-black {
  background-color: $color-black-soft;
  color: $color-white;
}

.section-turquoise {
  background-color: $color-turquoise;
  color: $color-white;
}

.section-footer {
  padding: 30px 0 40px;

  a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
